import $ from 'jquery'
import 'jquery-jpostal-ja'
import 'chartkick/chart.js'
require('@rails/ujs').start();
require('turbolinks').start();
require('@rails/activestorage').start();
require('channels');
require('../stylesheets/application.scss');
require('suneditor/dist/css/suneditor.min.css');
window.$ = $;
